import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import type { Profile } from "../db/types";

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export const titleCase = (str: string) => {
	return str
		.split(" ")
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
		.join(" ");
};

export const toSentenceCase = (str: string) => {
	return str
		.split("_")
		.map((word) => {
			if (
				word === "cat" ||
				word === "ipr" ||
				word == "soa" ||
				word == "fsg" ||
				word === "aml" ||
				word === "crm" ||
				word === "rpq" ||
				word === "ina" ||
				word === "ctf" ||
				word === "fna"
			) {
				return word.toUpperCase();
			} else {
				return word.charAt(0).toUpperCase() + word.slice(1);
			}
		})
		.join(" ");
};

export const canAccessAI = (user: Profile) => {
	switch (true) {
		case user.role === "admin":
			return true;
		case user.role === "cat":
			return true;
		case user.email.split("@").pop() === "knkt.com.au":
			return true;
		default:
			return false;
	}
};

//Handle types of error messages: from https://kentcdodds.com/blog/get-a-catch-block-error-message-with-typescript
type ErrorWithMessage = {
	message: string;
};

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
	return (
		typeof error === "object" &&
		error !== null &&
		"message" in error &&
		typeof (error as Record<string, unknown>).message === "string"
	);
}

function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
	if (isErrorWithMessage(maybeError)) return maybeError;

	try {
		return new Error(JSON.stringify(maybeError));
	} catch {
		// fallback in case there's an error stringifying the maybeError
		// like with circular references for example.
		return new Error(String(maybeError));
	}
}

export function getErrorMessage(error: unknown) {
	return toErrorWithMessage(error).message;
}

export function formatBytes(bytes: number, decimals = 2): string {
	if (bytes === 0) return "0 Bytes";

	const k = 1024;
	const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
	const i = Math.floor(Math.log(bytes) / Math.log(k));

	return `${Number.parseFloat((bytes / Math.pow(k, i)).toFixed(decimals))} ${sizes[i]}`;
}

export * from "./dates";
